import { GuideRepository } from '@/repository';
//import { DataService } from '@/utils';

const GuideController = { 
    
    formPostPrepend: 'tx_wwfotodownload_jsonguide[pin]',
    images: [],
    loginOk: false,

    async postLogin(formData) {
        //console.log('GuideController.postLogin', formData);
        formData = this.formPostPrepend + "=" + formData;
        //formData = DataService.buildPostData(formData, this.formPostPrepend, true);
        //console.log('GuideController.postLogin', formData);
        const response = await GuideRepository.postLogin(formData);
        //console.log('postLogin response', response);
        if (response.data.success) {
            //console.log('postLogin und success a no');
            this.loginOk = true;
        } else {
            this.loginOk = false;
        }
        return response;
    },

    async getImages() {
        
        const response = await GuideRepository.getImages();
        if (response.data.success) {
            this.images = response.data.images;
            return response;
        } else {
            return response;
        }
        
    }       
}

export default GuideController;