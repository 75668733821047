<template>
  <div class="form-property form-property--checkbox" :class="{ 'is-checked': value }">
    
    <input 
      type="checkbox" 
      :id="'input'+item.name" 
      v-model="value" 
      :name="item.name" 
      @change="handleChange($event, item)" 
      :placeholder="item.placeholder"
      :checked="value"
    />
    <label :for="'input'+item.name"><span class="checkbox-check"><i class="svgicon svgicon--check" v-html="icons.checked"></i></span>{{ item.label }} <span v-if="error" style="color:red; font-weight:bold;">- {{ error }}</span></label>
  </div>
</template>

<script>
import { defineComponent } from "vue"


export default defineComponent({
  name: "CheckboxField",
  computed: {
  },
  props: {
      handles: {
        type: Array,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      property: {
        type: String,
        default: ""
      },
      error: {
        type: String,
        default: ""
      }
  },
  data() {
    return {
      value: 0,
      icons: {
        checked: '<?xml version="1.0" encoding="UTF-8"?><svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.04 301.69"><path d="M4.11,203.22c-4.64-3.63-5.48-10.33-1.85-14.97,3.63-4.65,10.33-5.48,14.98-1.85l115.4,90.23L390.57,3.36c4.05-4.3,10.8-4.5,15.1-.45,4.3,4.05,4.5,10.8,.47,15.1L141.52,298.35v-.02c-3.73,3.98-9.96,4.52-14.35,1.09L4.11,203.2v.02Z"/></svg>'
      }
    }
  },
  watch: {
    storedValue(val) {
      this.value = val.value;
    }
  },
  created() {
    this.value = this.storedValue.value;
    this.emitter.on("onResetForm", () => {
      this.value = false;
    });
  },
  methods: {
    handleChange(ev, item) {
      console.log('checkbox value changed', this.value);
      this.$emit("onChange", ev, item, this.property, this.value);
    }
  },
});
</script>
