<template>
  <div class="content-sent-link" :class="{ 'is-visible': email != '' }">
    <h2>{{ $t('headlines.sent_link_success')}}</h2>
    <p> {{ $t('messages.sent_link') }}<br><br></p>
    <p> {{ $t('labels.email_address')}}: {{ email }}<br><br></p>
    <p><a href="/" class="link-back" @click.prevent="handleClickBack"><i class="svgicon svgicon--arrow svgicon--inline" v-html="icons.back"></i><span>{{ $t('buttons.back') }}</span></a></p>
  </div>
</template>

<script>
export default {
  name: "SentLink",
  data() {
    return {
      icons: {
        back: '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><style>.cls-1{fill:none;stroke:#414043;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><line class="cls-1" x1="1" y1="24" x2="47" y2="23.99"/><polyline class="cls-1" points="27 1 1 24 27 47"/></svg>'
      }
    }
  },
  props: {
    email: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleClickBack() {
      this.$emit('onClickBack');
    }
  }
};
</script>