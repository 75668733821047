import { FotoDownloadRepository } from '@/repository';

const ImageController = { 
    
    image: '',

    async getImage() {
        const fotoCode = 'MONSTER'
        FotoDownloadRepository.getImage(fotoCode);
    }    
}

export default ImageController;