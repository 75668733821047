<template>
  <div class="form--guidelogin" >
    <FormButton v-if="!loginOk" buttonType="submit" :label="$t('buttons.guidelogin')" name="open-guide-login" @onClick="handleClick" />
    <FormButton v-else buttonType="submit" :label="$t('buttons.guidelogout')" name="guide-logout" @onClick="handleLogout" />
  </div>
</template>

<script>
import { FormButton } from './fields';
import { GuideController } from '@/controller';

export default {
  name: "FormGuideLogin",
  components: {
    FormButton
  },
  data() {
    return {
      loginOk: false,
    }
  },
  props: {
  },
  created() {
    this.emitter.on("onGuideLoginSuccess", () => {
      this.loginOk = GuideController.loginOk;
    });
  },
  methods: {
    handleClick() {
      this.$emit("onOpenDialog");
      //this.$guidelogin.open();
    },
    handleLogout() {
      this.emitter.emit("onGuideLogout");
      this.loginOk = false;
      GuideController.loginOk = false;
    }
  
  }
};
</script>

