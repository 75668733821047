<template>
  <div class="customer-image" :class="{ 'hidden': !showImage }">
    <figure>
      <a :href="(image?image.src:'')" target="_blank">
        <img :src="(image?image.src:'')" :alt="(image?image.identifier:'')" :title="(image?image.identifier:'')" />
      </a>
    </figure>
    <a class="link--download" :href="(image?image.src:'')" target="_blank">{{ $t('messages.download_original') }}</a>
  </div>
</template>

<script>

export default {
  name: "ImageCustomer",
  components: {
  },
  data() {
    return {
    }
  },
  props: {
    image: {
      type: Object,
      default: null
    },
    showImage: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  }
};
</script>