export const Api = Object.freeze({
    BACKEND_URL: 'https://fotobox.kuenz-schnaps.at/backend/?type=1500', /* process.env.VUE_APP_BACKEND, */
    //BACKEND_URL: 'https://kfoto.webweb.works/backend/?type=1500', /* process.env.VUE_APP_BACKEND, */
    CREATE_DOWNLOAD_ACTION: '&tx_wwfotodownload_jsonfoto[action]=create',
    CREATE_DOWNLOAD_WITH_HASH_ACTION: '&tx_wwfotodownload_jsonfoto[action]=createWithHash',
    GETIMAGE_ACTION: '&tx_wwfotodownload_jsonfoto[action]=getImage',
    
    BACKEND_GUIDE_URL: 'https://fotobox.kuenz-schnaps.at/backend/?type=1600', /* process.env.VUE_APP_BACKEND, */
    //BACKEND_GUIDE_URL: 'https://kfoto.webweb.works/backend/?type=1600', /* process.env.VUE_APP_BACKEND, */
    GUIDELOGIN_ACTION: '&tx_wwfotodownload_jsonguide[action]=login',
    GETIMAGES_ACTION: '&tx_wwfotodownload_jsonguide[action]=getImages'
});
  