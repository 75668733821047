<template>
  <form class="form-customer" @submit.prevent="handleFormSubmit" :class="{ 'hidden': !showForm }">
    <InputField :item="items.fotoCode" property="fotoCode" @onChange="handleFieldChange" :storedValue="values.fotoCode" />
    <InputField :item="items.firstName" property="firstName" @onChange="handleFieldChange" :storedValue="values.firstName" />
    <InputField :item="items.lastName" property="lastName" @onChange="handleFieldChange" :storedValue="values.lastName" />
    <InputField :item="items.emailAddress" property="emailAddress" @onChange="handleFieldChange" :storedValue="values.emailAddress" />
    <CheckboxField :item="items.gdpr" property="gdpr" @onChange="handleFieldChange" :storedValue="values.gdpr" />
    <p class="form-property"><a class="link--gdpr" href="#" @click.prevent="handleShowGdpr" :title="$t('buttons.show_gdpr')">{{ $t('buttons.show_gdpr') }}</a></p>
    <FormButton :enabled="formOk===true" buttonType="submit" :label="$t('buttons.download')" name="submit-customer-form" @onClick="handleSubmitClick" />
  </form>
</template>

<script>
import { InputField, CheckboxField, FormButton } from './fields';
import { CustomerFormController, ImageController } from '@/controller';

export default {
  name: "CustomerForm",
  components: {
    InputField,
    CheckboxField,
    FormButton
  },
  data() {
    return {
      items: {
        fotoCode: {
          label: this.$t('fields.label.foto_code'),
          name: 'customerFotoCode',
          placeholder: this.$t('fields.placeholder.foto_code'),
          type: 'text'
        },
        firstName: {
          label: this.$t('fields.label.first_name'),
          name: 'customerFirstName',
          placeholder: this.$t('fields.placeholder.first_name'),
          type: 'text'
        },
        lastName: {
          label: this.$t('fields.label.last_name'),
          name: 'customerLastName',
          placeholder: this.$t('fields.placeholder.last_name'),
          type: 'text'
        },
        emailAddress: {
          label: this.$t('fields.label.emailAddress'),
          name: 'customerEmail',
          placeholder: this.$t('fields.placeholder.emailAddress'),
          type: 'email'
        },      
        gdpr: {
          label: this.$t('fields.label.gdpr'),
          name: 'customerGdpr',
          placeholder: "",
          type: "check"
        } 
      },
      values: {
        fotoCode: {
          value: ''
        },
        firstName: {
          value: ''
        },
        lastName: {
          value: ''
        },
        emailAddress: {
          value: ''
        },
        gdpr: {
          value: false
        }
      },      
      formOk: false,
    }
  },
  props: {
    showForm: {
      type: Boolean,
      default: true
    }
  },
  created() {
    /* this.emitter.on("onResetCustomerForm", () => {
      console.log('onResetCustomerForm');
      this.values.fotoCode.value = "";
      this.values.firstName.value = "";
      this.values.lastName.value = "";
      this.values.emailAddress.value = "";
    }); */
  },
  methods: {
    handleFormSubmit(ev) {
      console.log("onSubmit", ev.target);
    },
    handleFieldChange(ev, item, property, value) {
      console.log("onFieldChange", ev, item, property, value);
      this.values[property].value = value;
      this.checkFormOk();
    },
    async handleSubmitClick() {
      const response = await CustomerFormController.postNewDownloadForm(this.values);
      if (response) {
        console.log('handleSubmitClick emit ', response );
        this.$emit('onResponse', response.data.success, response.data);
      }
    },
    handleGetImageClick(ev) {
      console.log('handleGetImageClick', ev);
      ImageController.getImage();
    },
    resetForm: function() {
      this.values.fotoCode.value = "";
      this.values.firstName.value = "";
      this.values.lastName.value = "";
      this.values.emailAddress.value = "";
      this.emitter.emit("onResetForm");
    },
    checkFormOk: function() {
      this.formOk = (this.values.fotoCode.value && this.values.firstName.value && this.values.lastName.value && this.values.emailAddress.value && this.values.gdpr.value == true) == true;
    },    
    handleShowGdpr() {
      this.$emit("onShowGdpr");
    }    
  }
};
</script>