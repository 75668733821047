<template>
  <div class="form-property form-property-action">
    <button 
      :type="buttonType"
      @click.prevent="handleClick"
      :disabled="!enabled"
    >{{ label }}</button>
  </div>
</template>

<script>

export default {
  name: "Button",
  computed: {
  },
  props: {
    buttonType: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    enabled: {
      type: Boolean,
      default: true
    }    
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleClick(ev) {
      this.$emit("onClick", ev);
    }
  },
};
</script>