<template>
  <form class="form-customer" @submit.prevent="handleFormSubmit" :class="{ 'hidden': !showForm }">
    <InputField :item="items.hash" property="customerHash" :storedValue="values.hash" />
    <InputField :item="items.fotoCode" property="fotoCode" @onChange="handleFieldChange" :storedValue="values.fotoCode" />
    <FormButton buttonType="submit" :label="$t('buttons.download')" name="submit-customer-form" @onClick="handleSubmitClick" />
  </form>
</template>

<script>
import { InputField, FormButton } from './fields';
import { CustomerFormController, ImageController } from '@/controller';

export default {
  name: "CustomerHashForm",
  components: {
    InputField,
    FormButton
  },
  data() {
    return {
      items: {
        fotoCode: {
          label: this.$t('fields.label.foto_code'),
          name: 'customerFotoCode',
          placeholder: this.$t('fields.placeholder.foto_code'),
          type: 'text'
        },
        hash: {
          label: "",
          name: 'customerHash',
          placeholder: "",
          type: 'hidden'
        }
      },
      values: {
        fotoCode: {
          value: ''
        },
        hash: {
          value: ''
        }
      },      
    }
  },
  props: {
    showForm: {
      type: Boolean,
      default: true
    },
    hash: {
      type: Object,
      default: null
    }
  },
  created() {
    console.log('created', this.hash);
    this.values.hash.value = this.hash.value;
  },
  mounted() {
    console.log('mounted', this.hash);
    this.values.hash.value = this.hash.value;
  },
  watch: {
    hash(val) {
      console.log('changed hash: ', val);
      //TODO
      this.values.hash.value = val.value;
    }
  },
  methods: {
    handleFormSubmit(ev) {
      console.log("onSubmit", ev.target);
    },
    handleFieldChange(ev, item, property, value) {
      //console.log("onFieldChange", ev, item, property, value);
      this.values[property].value = value;
    },
    async handleSubmitClick() {
      const response = await CustomerFormController.postDownloadHashForm(this.values);
      if (response) {
        console.log('handleSubmitClick emit ', response );
        this.$emit('onResponse', response.data.success, response.data);
      }
    },
    handleGetImageClick(ev) {
      console.log('handleGetImageClick', ev);
      ImageController.getImage();
    }
  }
};
</script>