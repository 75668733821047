const DataService = { 

    buildPostData(data, prefix, useValue) {
        var postData = "";
        for (var key in data) {
          if (key !== "uid" && data[key] != 0) {
            postData += (postData.length ? "&" : "") + "[" + key + "]" + "=" +
              encodeURIComponent(useValue?data[key]['value']:data[key]);
          }
        }
        console.log('postData before', postData);
        //postData = postData.replace(/=/g, "]=").replace(/\&/g, "&" + prefix);
        /* eslint-disable-next-line */
        postData = postData.replace(/\&/g, "&" + prefix);
        console.log('postData after', postData);
        postData = prefix + postData;
        if (postData.indexOf("[uid]")) {
          //replace uid with __identity for property mapping in backend
          postData = postData.replace(/\[uid\]/, "[__identity]");
        }
        return postData;
      }

}

export default DataService;