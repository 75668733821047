<template>
    <div class="guidelogin" :style="style"  @click.self="handleWrapperClick" :class="{ 'is--active': active }">
      <div class="guidelogin__inner">
        <header class="guidelogin__header">
          <h2>PIN eingeben</h2>
        </header>
        <form @submit.prevent="submit" novalidate class="form--login">
          <div class="form-property">
            <p class="form__error" v-if="isError">{{ errorHeader }}</p>
          </div>
          <div class="form-property">
            <label for="pin">PIN*</label>
            <input placeholder="Pin" type="password" class="form-control" id="pin" v-model.trim="form.pin" autocomplete="false" @keydown="handlePinKeyDown" />
          </div>
          <div class="form-property form-property-action">
            <button @click.prevent="onSubmit" v-if="!submitting" type="primary" class="btn btn-primary" :disabled="submitting">
              Bestätigen
            </button>
            <span class="login__loading" v-if="submitting">Überprüfen ... <img src="../../assets/img/loader.svg" /></span>            
            <a href="#" class="close guidelogin-close" @click.prevent="handleCloseClick"><i class="svgicon svgicon--close" v-html="icons.close"></i></a>
          </div>
        </form>
      </div>
    </div>
</template>

<script>

export default {
  name: "GuideLoginDialog",
  data() {
    return {
      isError: false,
      errorHeader: "Anmeldung nicht erfolgreich, falscher Pin.",
      submitting: false,
      form: {
        pin: ""
      },
      icons: {
        close: '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><style>.cls-1{fill:none;stroke:#414043;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><line class="cls-1" x1="1" y1="1" x2="47" y2="47"/><line class="cls-1" x1="1" y1="47" x2="47" y2="1"/></svg>'
      }
    };
  },
  computed: {
    style() {
      return this.active ? "display: flex;" : "display: none";
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  created() {
    this.emitter.on("onGuideLoginError", () => {
      this.submitting = false;
      this.form.pin = "";
      this.isError = true;
    });
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.submitting = true;
      this.$emit("onLogin", this.form.pin);
    },
    reload() {
      window.location = "";
    },
    initShow() {
    },
    handleWrapperClick() {
      this.$emit("onClose");
    },
    handleCloseClick() {
      this.$emit("onClose");
    },
    handlePinKeyDown() {
      this.isError = false;
    }
  }
};
</script>