
import axios from "axios"; 
import { Api } from "@/api";

const FotoDownloadRepository = { 

    /**
     * postNewDownloadForm
     * 
     * @param {String} postData
     * 
     * @returns {Object} server response
     */    
     async postNewDownloadForm(postData) {
        //const postData = buildPostData(statement.getData(), "tx_makingstrategy_statement[issue][") + "&" + buildPostData(statement.getPositions()[positionIndex], "tx_makingstrategy_statement[position][") + "&tx_makingstrategy_statement[activeView]=" + activeViewId;
        try {
            let response = await axios.create().post(Api.BACKEND_URL + Api.CREATE_DOWNLOAD_ACTION, postData);
            if (response.data.success) {
                console.log('FotoDownloadRepository -> postNewDownloadForm', response);
                return response;
            } else {
                console.log('FotoDownloadRepository -> postNewDownloadForm errors', response);
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },     

    /**
     * postDownloadHashForm
     * 
     * @param {String} postData
     * 
     * @returns {Object} server response
     */    
     async postDownloadHashForm(postData) {
        //const postData = buildPostData(statement.getData(), "tx_makingstrategy_statement[issue][") + "&" + buildPostData(statement.getPositions()[positionIndex], "tx_makingstrategy_statement[position][") + "&tx_makingstrategy_statement[activeView]=" + activeViewId;
        try {
            let response = await axios.create().post(Api.BACKEND_URL + Api.CREATE_DOWNLOAD_WITH_HASH_ACTION, postData);
            if (response.data.success) {
                console.log('FotoDownloadRepository -> postDownloadHashForm', response);
                return response;
            } else {
                console.log('FotoDownloadRepository -> postDownloadHashForm errors', response);
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },

    /**
     * getImage
     * 
     * @param {String} fotoCode
     * 
     * @returns {Object} server response
     */    
     async getImage(fotoCode) {
        //const postData = buildPostData(statement.getData(), "tx_makingstrategy_statement[issue][") + "&" + buildPostData(statement.getPositions()[positionIndex], "tx_makingstrategy_statement[position][") + "&tx_makingstrategy_statement[activeView]=" + activeViewId;
        try {
            let response = await axios.create({withCredentials: true}).get(Api.BACKEND_URL + Api.GETIMAGE_ACTION + '&tx_wwfotodownload_jsonfoto[fotoCode]=' + fotoCode);
            if (response.data.success) {
                console.log('FotoDownloadRepository -> getImage', response);
                return response;
            } else {
                console.error('StatementDataService -> error', response.data);
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            //console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },        
}

export default FotoDownloadRepository;