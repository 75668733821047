<template>
  <div class="form--customer-logout" >
      <FormButton buttonType="submit" :label="$t('buttons.customerlogout')" name="guide-logout" @onClick="handleLogout" />
  </div>
</template>

<script>
import { FormButton } from './fields';

export default {
  name: "FormCustomerLogout",
  components: {
    FormButton
  },
  data() {
    return {
      loginOk: false,
    }
  },
  props: {
  },
  created() {
    /* this.emitter.on("onGuideLoginSuccess", () => {
      this.loginOk = GuideController.loginOk;
    }); */
  },
  methods: {
    handleLogout() {
      console.log('emit Customer logout');
      this.emitter.emit("onCustomerLogout");
    }
  
  }
};
</script>

