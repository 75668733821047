<template>
  <div class="guide-images" :class="{ 'hidden': !showImages }" v-if="showImages">
    <figure 
        v-for="(image, index) in images"
        :key="index"
        v-bind="image"
      >
      <a :href="(image?image.src:'')" target="_blank">
        <figure>
          <img :src="(image?image.src:'')" :alt="(image?image.identifier:'')" :title="(image?image.identifier:'')" />
          <figcaption>{{ (image?image.identifier:'') }}</figcaption>
        </figure>
      </a>
    </figure>
  </div>
</template>

<script>

export default {
  name: "ImagesGuide",
  components: {
  },
  data() {
    return {
    }
  },
  props: {
    images: {
      type: Array,
      default: null
    },
    showImages: {
      type: Boolean,
      default: false
    }
  },
  watch:{
  },
  methods: {
  }
};
</script>