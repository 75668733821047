
import axios from "axios"; 
import { Api } from "@/api";

const GuideRepository = { 

    /**
     * postNewDownloadForm
     * 
     * @param {String} postData
     * 
     * @returns {Object} server response
     */    
     async postLogin(postData) {
        //const postData = buildPostData(statement.getData(), "tx_makingstrategy_statement[issue][") + "&" + buildPostData(statement.getPositions()[positionIndex], "tx_makingstrategy_statement[position][") + "&tx_makingstrategy_statement[activeView]=" + activeViewId;
        try {
            const response = await axios.create().post(Api.BACKEND_GUIDE_URL + Api.GUIDELOGIN_ACTION, postData);
            if (response.data.success) {
                console.log('GuideRepository -> postLogin', response);
                return response;
            } else {
                console.log('GuideRepository -> postLogin errors', response);
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },     

    /**
     * getImage
     * 
     * @returns {Object} server response
     */    
    async getImages() {
        //const postData = buildPostData(statement.getData(), "tx_makingstrategy_statement[issue][") + "&" + buildPostData(statement.getPositions()[positionIndex], "tx_makingstrategy_statement[position][") + "&tx_makingstrategy_statement[activeView]=" + activeViewId;
        try {
            const response = await axios.create({withCredentials: true}).get(Api.BACKEND_GUIDE_URL + Api.GETIMAGES_ACTION);
            if (response.data.success) {
                console.log('GuideRepository -> getImages', response);
                return response;
            } else {
                console.error('GuideRepository -> error', response.data);
                return response;
            }            
        } catch (error) {
            //statement.resetStateValues();
            //console.error(error);
            //ErrorHandler.createSystemError(error.message);
            return {};
        }
    },
}

export default GuideRepository;