<template>
  <div class="container-gdpr" :class="{ 'is-visible': visible }">
    <a href="#" class="link--close" @click.prevent="handleCloseClick"><i class="svgicon svgicon--close" v-html="icons.close"></i></a>
    <div class="content-gdpr">
      <h2>{{ $t('headlines.gdpr') }}</h2>
      <div class="bodytext">
        <p>Allgemeine Datenschutzerklärung</p>
        <p>Durch die Nutzung unserer Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäß der nachfolgenden Beschreibung einverstanden:</p>
        <p>Unsere Website kann grundsätzlich ohne Registrierung besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden.</p>
        <p>Personenbezogene Daten, insbesondere Vor-, Nachname, und die E-Mail-Adresse werden soweit möglich auf freiwilliger Basis erhoben (z.B. beim Download des Bildes). Mit der Eingabe Ihrer Daten und dem Download Ihres Bildes erklären Sie sich für die Nutzung Ihrer Daten einverstanden. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.</p>
        <p><strong>Kuenz Naturbrennerei GmbH</strong><br>
        Gödnach 2<br>
        9991 Dölsach<br>
        T +43 (0)4852 64307<br>
        F +43 (0)4852 64307-6<br>
        E info@schnaps-kuenz.at<br>
        W www.kuenz-schnaps.at</p>
        <p>UID: ATU72395678<br>
        Firmenbuchnummer: FN 473988t<br>
        Rechtsform: Gesellschaft mit beschränkter Haftung<br>
        Gewerbe: Handelsgewerbe und Handelsagenten gemäß § 124 Z 10 GewO 1994<br>
        Gewerbebehörde: Bezirkshauptmannschaft Lienz<br>
        Zuständige Kammer: WK Tirol</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "Gdpr",
  data() {
    return {
      icons: {
        close: '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.92 409.92"><path d="M380.8,5.04L216.72,169.12c-6.72,6.72-17.36,6.72-23.52,0L29.12,5.04C22.4-1.68,11.76-1.68,5.6,5.04-1.12,11.76-1.12,22.4,5.6,28.56L169.68,192.64c6.72,6.72,6.72,17.36,0,23.52L5.04,380.8c-6.72,6.72-6.72,17.36,0,23.52,6.72,6.72,17.36,6.72,23.52,0L192.64,240.24c6.72-6.72,17.36-6.72,23.52,0l164.64,164.64c6.72,6.72,17.36,6.72,23.52,0,6.72-6.72,6.72-17.36,0-23.52L240.8,216.72c-6.72-6.72-6.72-17.36,0-23.52L404.88,29.12c6.72-6.72,6.72-17.36,0-23.52-6.72-6.72-17.36-6.72-24.08-.56h0Z"/></svg>'
      }
    }
  },
  props: {
    images: {
      type: Array,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  created() {
 
  },
  methods: {
    handleCloseClick() {
      this.$emit("onClose");
    }
  }
});
</script>