import { FotoDownloadRepository } from '@/repository';
import { DataService } from '@/utils';
import { ImageController } from './';

const CustomerFormController = { 
    
    formPostPrepend: 'tx_wwfotodownload_jsonfoto[fotoDownload]',
    formPostHashPrepend: 'tx_wwfotodownload_jsonfoto',
    sentEmailTo: '',

    async postNewDownloadForm(formData) {
        formData = DataService.buildPostData(formData, this.formPostPrepend, true);
        if ( process.env.VUE_APP_ELECTRON ) formData += '&tx_wwfotodownload_jsonfoto[sendLink]=1';
        console.log('CustomerFormController.postNewDownloadForm', formData);
        const response = await FotoDownloadRepository.postNewDownloadForm(formData);
        if (response) {
            if (response.data.success) {
                ImageController.image = response.data.image;
                CustomerFormController.sentEmailTo = response.data.email;
            }
            return response;
        }
    },

    async postDownloadHashForm(formData) {
        formData = DataService.buildPostData(formData, this.formPostHashPrepend, true);
        console.log('CustomerFormController.postDownloadHashForm', formData);
        const response = await FotoDownloadRepository.postDownloadHashForm(formData);
        if (response) {
            if (response.data.success) {
                ImageController.image = response.data.image;
            }
            return response;
        }
    },

    /* async getImage() {
        console.log('CustomerFormController.getImage');
        const fotoCode = 'MONSTER'
        FotoDownloadRepository.getImage(fotoCode);
    }     */
}

export default CustomerFormController;