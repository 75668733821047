<template>
  <div class="form-property">
    <label :for="'input'+item.name">{{ item.label }}</label>
    <input 
      :type="item.type" 
      :id="'input'+item.name" 
      v-model="value" 
      :name="item.name" 
      :placeholder="item.placeholder"
      @change="handleChange($event, item)" 
    />
  </div>
</template>

<script>

export default {
  name: "Input",
  computed: {
  },
  props: {
      handles: {
        type: Array,
        default: null
      },
      item: {
        type: Object,
        default: null
      },
      storedValue: {
        type: Object,
        default: null
      },
      property: {
        type: String,
        default: ""
      }
  },
  data() {
    return {
      value: 0,
    }
  },
  watch: {
    storedValue(val) {
      this.value = val.value;
    }
  },
  created() {
    this.value = this.storedValue.value;
    this.emitter.on("onResetCustomerForm", () => {
      this.value = "";
    });
    this.emitter.on("onCustomerLogout", () => {
      this.value = "";
    });    
  },
  methods: {
    handleChange(ev, item) {
      this.$emit("onChange", ev, item, this.property, this.value);
    }
  },
};
</script>